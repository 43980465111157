//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { preFetchPage, seoRedirects } from '@/helpers/router';
export default {
  name: 'SeoPage',
  preFetch: preFetchPage,
  beforeRouteUpdate: seoRedirects,
  meta: function meta() {
    try {
      return {
        title: this.seoPage.data.title || 'PiasterCreek',
        meta: {
          description: {
            name: 'description',
            content: this.seoPage.data.description
          },
          keywords: {
            name: 'keywords',
            content: this.seoPage.data.keywords
          }
        }
      };
    } catch (e) {
      this.$store.log(e);
      return {};
    }
  },
  computed: {
    seoPage: function seoPage() {
      return this.$store.getters['statics/seoPage'];
    },
    lang: function lang() {
      return this.$store.getters['lang/lang'];
    },
    defaultLang: function defaultLang() {
      return this.$store.getters['lang/defaultLang'];
    },
    gameMode: function gameMode() {
      return this.$store.getters['player/gameMode'];
    },
    hasDeposits: function hasDeposits() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['player/profile']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.hasDeposits;
    },
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    }
  },
  methods: {
    linkToMainPage: function linkToMainPage() {
      return this.lang === this.defaultLang ? '/' : "/".concat(this.lang);
    },
    switchToTourny: function switchToTourny() {
      this.$store.dispatch('player/switchToTourny');
    },
    switchToSweeps: function switchToSweeps() {
      this.$store.dispatch('player/switchToSweeps');
    },
    linkTo: function linkTo(_ref) {
      var page = _ref.page,
          url = _ref.url;
      return this.$store.getters['lang/linkTo']({
        page: page,
        params: url
      });
    }
  }
};